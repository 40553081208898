.App {
  display: flex;
  align-items: center;
  justify-content: center;

  .launchpad-page {
    max-width: 430px;
  }

  .logo-section {
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    #salt {
      max-width: 160px;
    }

    p {
      margin: 0 8px;
      font-size: 2.5rem;
      font-weight: 450;
    }

    #church {
      width: 60px;
      height: 60px;
      border-radius: 12px;
    }
  }

  .content {
    margin: 0 16px;

    h2 {
      margin-bottom: 0;
      margin-top: 16px;
    }

    p {
      margin: 0;
      margin-bottom: 16px;
      font-size: 1.25rem;
    }

    a {
      text-decoration: underline;
      color: #f58b8b;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #f58b8b; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
}
